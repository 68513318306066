<template>
  <div class="disputes" id="disputes">
    <div class="ui grid container faq">
      <div class="intro-content">
        <p class="path">Frequently Asked Questions > Disputes</p>
        <h3>Disputes</h3>
        <h4 id="dspt1">What is a transaction dispute?</h4>
        <div class="paragraph">
          <p>
            A transaction dispute is when a customer has done a legitimate transaction but has double billing, over-billing, problems with Cash In or Cash Out, or when he or she suspects an unusual transaction or movement in his or her TayoCash account.
          </p>

          <p class="vtxt-align">Examples of Transaction Disputes:
            <ul>
              <li>Incorrect amount for Cash In or Cash Out, bills payment, or fund transfer</li>
              <li>Incorrect account number for Cash In or Cash Out, bills payment, or fund transfer</li>
              <li>Bills payment not posted within the prescribed period</li>
              <li>Different amount reflecting in the TayoCash account instead of what was paid to or withdrawn from the TayoCash
              Partner or Merchant</li>
              <li>Reasonable suspicion of unauthorized use or movement in the TayoCash wallet</li>
            </ul>
          </p>

        </div>

        <h4 id="dspt2">I want to file a transaction dispute, what should I do?</h4>
        <div class="paragraph">
          <p>
            You may contact TayoCash Customer Care by calling the Service Hotline <span class="vtxt-align">8888-TAYO</span>. A Customer Care Representative will take your call and provide you with the next steps.
          </p>
          <p>
            Please keep in mind that all transaction disputes must be reported within <span class="vtxt-align">15 days</span> from the date of transaction.
          </p>
          <p>
            You may also download and accomplish the <a class="link" href="https://assets.prod.tayocash.com/forms/transaction_dispute_form.pdf" download target="_blank">Transaction Dispute Form</a>. You may submit your accomplished form via the TayoCash <a class="link" href="/helpcenter">Help Center</a> in the app or website or via email to <a class="link" href="mailto:ccg@tayocash.com">ccg@tayocash.com</a>. Upon submission of the transaction dispute, a ticket will be generated for tracking purposes.
          </p>
        </div>

        <h4 id="dspt3">Where can I find the dispute form?</h4>
        <div class="paragraph">
          <p>
            A downloadable <a class="link" href="https://assets.prod.tayocash.com/forms/transaction_dispute_form.pdf" download target="_blank">Transaction Dispute Form</a> can be found at the bottom of the <a class="link" href="/helpcenter">Help Center</a> page.
          </p>
        </div>

        <h4 id="dspt4">How long does it take for my dispute to get resolved?</h4>
        <div class="paragraph">
          <p>
            The time it takes to resolve a dispute will highly depend on the nature of the issue. We guarantee that our Customer Care Representatives will provide a reasonable timeframe and the necessary next steps to resolve any dispute. You may follow up on the Ticket by sending an email to <a class="link" href="mailto:ccg@tayocash.com">ccg@tayocash.com</a> with this format:
          </p>
          <p>
            Email Subject: *Ticket Number*_REQUEST FOR FOLLOW UP <br>
            Email Content: *Enter the nature of your concern and the date of the last update.*
          </p>
        </div>

        <h4 id="dspt5">Can I talk to someone about my dispute?</h4>
        <div class="paragraph">
          <p>For assistance and other inquiries, you may call our Service Hotline <span class="vtxt-align">8888-TAYO</span>. We offer quick self-service solutions via our Interactive Voice Response system. In case you need more help with your concern, you may also speak with our Customer Service Representative. Automated services are available 24/7 while our Customer Care Representatives are available to take your call from <span class="vtxt-align">Monday to Friday</span> from <span class="vtxt-align">8:00 AM to 5:30 PM</span>.</p>
        </div>

        <div class="buttons">
          <a href="/faq/complaints" class="yellow">BACK</a>
          <a href="/faq/maintenance" class="white">NEXT</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'disputes'
}
</script>

<style scoped lang='scss'></style>
